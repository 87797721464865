import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar, About, Contact, Header, Skills, Works, Testimonials, Footer } from './components';
import Work_page from './components/Work_page/Work_page';
import GradientBackground from './components/GradientBackground';
import './app.scss';

const App = () => {
  return (
    <Router>
      <GradientBackground>
        <div className="app">
          <Navbar />
          <Routes>
            <Route path="/" element={
              <>
                <Header />
                <About />
                <Skills />
                <Works />
                <Testimonials />
                <Contact />
                <Footer />
              </>
            } />
            <Route path="/works/:id" element={<Work_page />} />
          </Routes>
        </div>
      </GradientBackground>
    </Router>
  );
}

export default App;



