import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { BsGithub } from 'react-icons/bs';
import { TbWorldWww } from "react-icons/tb";
import Works_data from '../Works_data';
import './Work_page.scss';

const Work_page = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const work = Works_data.find(work => work.id === parseInt(id));
  const currentIndex = Works_data.findIndex(work => work.id === parseInt(id));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  if (!work) {
    return <div>Work not found</div>;
  }

  const handlePrev = () => {
    const prevIndex = (currentIndex - 1 + Works_data.length) % Works_data.length;
    navigate(`/works/${Works_data[prevIndex].id}`);
  };

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % Works_data.length;
    navigate(`/works/${Works_data[nextIndex].id}`);
  };

  return (
    <div className='app__Work-page'>
      <div className='app__Work-page-container'>
        <div className="app__Work-page-content-content">
          <motion.div
            whileInView={{ x: [-50, 0], opacity: [0, 1] }}
            transition={{ duration: 1 }}
            className="app__Work-page-content-title">{work.name}
          </motion.div>
          
          <div className="app__Work-page-content">
            <motion.div
              whileInView={{ y: [-20, 0], opacity: [0, 1] }}
              transition={{ duration: 1 }}
              className="app__Work-page-content-images">
              {work.image2 && <img src={work.image2} alt={`${work.name} 1`} />}
              {work.image3 && <img src={work.image3} alt={`${work.name} 2`} />}
            </motion.div>

            <motion.div
              whileInView={{ y: [-30, 0], opacity: [0, 1] }}
              transition={{ duration: 1.5 }}
              className="app__Work-page-content-description"
              dangerouslySetInnerHTML={{ __html: work.description }}>
            </motion.div>
            
            <motion.div
              whileInView={{ y: [-30, 0], opacity: [0, 1] }}
              transition={{ duration: 1 }}
              className="app__Work-page-content-icon">
              {work.git && (
              <a href={work.git} target="_blank" rel="noopener noreferrer">
                <BsGithub />
              </a>
              )}
              {work.link && (
                <a href={work.link} target="_blank" rel="noopener noreferrer">
                  <TbWorldWww />
                </a>
              )}
            </motion.div>

            {work.video && (
              <motion.div
                whileInView={{ y: [-20, 0], opacity: [0, 1] }}
                transition={{ duration: 1 }}
                className="app__Work-page-content-video">
                <video controls>
                  <source src={work.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </motion.div>
            )}
          </div>

          <div className="app__Work-page-navigation">
            <button onClick={handlePrev} className="nav-button">Previous Work</button>
            <button onClick={handleNext} className="nav-button">Next Work</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work_page;
