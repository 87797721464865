import { images } from '../constants';

const Works_data = [
    {
        id: 1,
        image: `${images.card_2}`,
        image2: `${images.Plateperfection_Screenshot1}`,
        image3: `${images.Plateperfection_Screenshot2}`,
        name: "Plate Perfection",
        description: `
          <p>Plate Perfection is a fine cuisine restaurant website that elevates your dining experience. </p>
          <p>Built in JavaScript React, it features detailed descriptions of each exquisite dish, vivid imagery, and an intuitive cart system for seamless ordering.</p>
          <p>Enjoy gourmet meals from the comfort of your home, exploring a curated menu that promises culinary excellence. Plate Perfection brings restaurant-quality dining to your fingertips.</p>
          <br>
          <p><b>Frontend: JavaScript / React / SCSS</b></p>
        `,
        git: "https://github.com/andrematoscreative",
        link: "https://plate-perfection.vercel.app",
    },
    {
        id: 2 ,
        image: `${images.card_1}`,
        image2: `${images.Datasphere_logo1}`,
        image3: `${images.Datashere_Screenshot}`,
        video: `${images.Datasphere_video}`,
        name: "Datasphere",
        description: `
          <p>This is a full-stack app designed to manage, organize and easily access personal contact data.</p>
          <p>Built with a robust Python / Flask backend and a dynamic JavaScript / React frontend, DataSphere offers seamless data handling and an intuitive user experience.</p>
          <p>This versatile app supports future development, enabling additional features and scalability to meet evolving user needs.</p>
          <br>
          <p><b>Frontend: JavaScript / React / SCSS</b></p>
          <p><b>Backend: Python / Flask</b></p>
        `,
        git: "https://github.com/andrematoscreative",
    },
    {
        id: 3,
        image: `${images.card_3}`,
        name: "Coming Soon",
        description: `
          <p></p>
          <p></p>
          <p></p>
        `,
    },
];

export default Works_data;