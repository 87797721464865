/*import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { images } from '../../constants';
import './Header.scss';

const Header = () => {
  const [firstLineText, setFirstLineText] = useState("");
  const [secondLineText, setSecondLineText] = useState("");
  const fullText = ["Hello World", "I'm a"];
  const [index, setIndex] = useState(0);
  const [line, setLine] = useState(0);
  const [showCursor, setShowCursor] = useState(true);

  useEffect(() => {
    const blinkInterval = setInterval(() => {
      setShowCursor(prev => !prev);
    }, 500);

    if (line < fullText.length) {
      if (index < fullText[line].length) {
        setTimeout(() => {
          if (line === 0) {
            setFirstLineText(firstLineText + fullText[line][index]);
          } else {
            setSecondLineText(secondLineText + fullText[line][index]);
          }
          setIndex(index + 1);
        }, 170);
      } else {
        setLine(line + 1);
        setIndex(0);
      }
    }

    return () => clearInterval(blinkInterval);
  }, [index, line]);

  return (
    <div className='app__header' id='Home'>
      <div className='app__master-container app__header-container'>
        <div className='app__header-info'>
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 1 }}
            className='app__header-head-text'>
            {firstLineText}
            {line === 0 && showCursor && <span className='blink'>|</span>}
            <br />
            {secondLineText}
            {line === 1 && showCursor && <span className='blink'>|</span>}
          </motion.div>
          <div className='app__header-description'>
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 1 }}
              className='app__header-description-text1'>
              <p>&lt;Visual Designer/&gt;</p>
            </motion.div>
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 1 }}
              className='app__header-description-text2'>
              <p>&lt;Software Web Developer/&gt;</p>
            </motion.div>
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration:1 }}
              className='app__header-description-text3'>
              <p>&lt;Technology Lover/&gt;</p>
            </motion.div>
          </div>
        </div>
        <motion.div
          whileInView={{ opacity: [0, 1] }}
          transition={{ duration: 6 }}>
          <img src={images.logo2} alt='header_background'/>
        </motion.div>
      </div>
    </div>
  );
};

export default Header;*/

import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { images } from '../../constants';
import './Header.scss';

const Header = () => {
  const [firstLineText, setFirstLineText] = useState("");
  const [secondLineText, setSecondLineText] = useState("");
  const fullText = ["Hello World", "I'm a"];
  const [index, setIndex] = useState(0);
  const [line, setLine] = useState(0);
  const [showCursor, setShowCursor] = useState(true);
  const [typingComplete, setTypingComplete] = useState(false);

  useEffect(() => {
    const blinkInterval = setInterval(() => {
      setShowCursor(prev => !prev);
    }, 700);

    if (line < fullText.length) {
      if (index < fullText[line].length) {
        setTimeout(() => {
          if (line === 0) {
            setFirstLineText(firstLineText + fullText[line][index]);
          } else {
            setSecondLineText(secondLineText + fullText[line][index]);
          }
          setIndex(index + 1);
        }, 190);
      } else {
        setLine(line + 1);
        setIndex(0);
      }
    } else {
      setTypingComplete(true);
    }

    return () => clearInterval(blinkInterval);
  }, [index, line]);

  return (
    <div className='app__header' id='Home'>
      <div className='app__master-container app__header-container'>
        
        <div className='app__header-info'>
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 1 }}
            className='app__header-head-text'>
            {firstLineText}
            {line === 0 && showCursor && <span className='blink'>|</span>}
            <br />
            {secondLineText}
            {line === 1 && !typingComplete && showCursor && <span className='blink'>|</span>}
          </motion.div>
          
          <div className='app__header-description'>
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 1 }}
              className='app__header-description-text1'>
              <p>&lt;Visual Designer/&gt;</p>
            </motion.div>
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 1 }}
              className='app__header-description-text2'>
              <p>&lt;Software Web Developer/&gt;</p>
            </motion.div>
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 1 }}
              className='app__header-description-text3'>
              <p>&lt;Technology Lover/&gt;</p>
            </motion.div>
          </div>
          
        </div>
        <motion.div
          whileInView={{ opacity: [0, 1] }}
          transition={{ duration: 5 }}>
          <img src={images.back} alt='background image'/>
        </motion.div>
      </div>
    </div>
  );
};

export default Header;