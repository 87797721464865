import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.scss';
import { HiMenu } from 'react-icons/hi';
import { SlClose } from 'react-icons/sl';
import { motion } from 'framer-motion';
import { images } from '../../constants';

const Navbar = () => {
    const [toggle, setToggle] = useState(false);
    const [activeLink, setActiveLink] = useState('Home');
    const location = useLocation();

    const sections = ['Home', 'About', 'Skills', 'Works', 'Testimonials', 'Contact'];

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.7,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActiveLink(entry.target.id);
                }
            });
        }, options);

        sections.forEach((section) => {
            const element = document.getElementById(section);
            if (element) observer.observe(element);
        });

        return () => {
            sections.forEach((section) => {
                const element = document.getElementById(section);
                if (element) observer.unobserve(element);
            });
        };
    }, [sections]);

    useEffect(() => {
        if (location.hash) {
            const sectionId = location.hash.substring(1);
            scrollToSection(sectionId);
        }
    }, [location]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='app__navbar'>
            <div className='app__master-container app__navbar-all'>
                <div className='app__navbar-logo'>
                    <Link to="/#Home" onClick={() => setActiveLink('Home')}>
                        <img src={images.logo} alt='andre matos creative logo' />
                        ANDRÉ MATOS CREATIVE
                    </Link>
                </div>

                <ul className='app__navbar-links'>
                    {sections.map((item) => (
                        <li key={`link-${item}`}>
                            <div />
                            <Link
                                to={`/#${item}`}
                                onClick={() => setActiveLink(item)}
                                className={item === activeLink ? 'active' : ''}
                            >
                                {item}
                            </Link>
                        </li>
                    ))}
                </ul>

                <div className='app__navbar-menu'>
                    <HiMenu onClick={() => setToggle(true)} />
                    {toggle && (
                        <motion.div whileInView={{ x: [300, 0] }} transition={{ duration: 0.3, ease: 'easeOut' }}>
                            <SlClose onClick={() => setToggle(false)} />
                            <ul>
                                {sections.map((item) => (
                                    <li key={item}>
                                        <Link
                                            to={`/#${item}`}
                                            onClick={() => {
                                                setToggle(false);
                                                setActiveLink(item);
                                            }}
                                        >
                                            {item}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </motion.div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Navbar;




