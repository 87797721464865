// src/components/GradientBackground.js
import React, { useEffect } from 'react';
import './GradientBackground.scss';

const GradientBackground = ({ children }) => {
  useEffect(() => {
    const interBubble = document.querySelector('.interactive');
    let curX = 0;
    let curY = 0;
    let tgX = 0;
    let tgY = 0;

    const move = () => {
      curX += (tgX - curX) / 20;
      curY += (tgY - curY) / 20;
      interBubble.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
      requestAnimationFrame(move);
    };

    const handleMouseMove = (event) => {
      tgX = event.clientX - (window.innerWidth / 2);
      tgY = event.clientY - (window.innerHeight / 2);
    };

    window.addEventListener('mousemove', handleMouseMove);

    move();

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <>
      <div className="gradient-bg">
        <div className="gradients-container">
          <div className="g1"></div>
          <div className="g2"></div>
          <div className="g3"></div>
          <div className="g4"></div>
          <div className="interactive"></div>
        </div>
      </div>
      {children}
    </>
  );
};

export default GradientBackground;
