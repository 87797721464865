import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Works_data from '../../components/Works_data';
import './Works.scss';

const Works = () => {
  return (
    <div className='app__Work' id='Works'>
      <div className='app__master-container'>
        <div className='app__Work-info'>
          
          <div className='app__Work-title'>
            <motion.div
              whileInView={{ x: [-50, 0], opacity: [0, 1] }}
              transition={{ duration: 1 }}
              className='app__Work-title-title'>Work</motion.div>
            <motion.div
              whileInView={{ x: [-50, 0], opacity: [0, 1] }}
              transition={{ duration: 1 }}
              className='app__Work-title-info'>
              <p>Check some of my work. Crafting the future, one project at a time.</p>
              <div className='app__Work-blink'>|</div>
            </motion.div>
          </div>

          <motion.div
            whileInView={{ y: [-30, 0], opacity: [0, 1] }}
            transition={{ duration: 1 }} className="app__Work-cards-container">
            {Works_data.map(work => (
              <Link to={`/works/${work.id}`} key={work.id} className="app__Work-card">
                <img src={work.image} alt={work.name} className="card-image" />
                <div className="card-description">
                  {work.name}
                </div>
                
                <div className='app__Work-explore'>
                  Explore 
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 24 24" 
                    fill="currentColor" 
                    className="arrow-icon">
                    <path fillRule="evenodd" 
                      d="M13.293 17.293a1 1 0 011.414 0l5-5a1 1 0 010-1.414l-5-5a1 1 0 00-1.414 1.414L16.586 11H5a1 1 0 100 2h11.586l-3.293 3.293a1 1 0 000 1.414z" 
                      clipRule="evenodd"/>
                  </svg>
                </div>
              
              </Link>
            ))}
          </motion.div>
        
        </div>
      </div>
    </div>
  );
};

export default Works;
